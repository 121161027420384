<template>
  <div class="parental-container">
    <div v-if="mainLoading" class="parental-main-loader">
      <Loading size="40px" />
    </div>
    <PinModal
      id="pc-pin-modal"
      :open="showPinModal"
      @onClose="closePinModal"
      :mode="pinMode"
      @onActionComplete="onPinModalActionComplete"
      :pinError="pinModalError"
      @onForgotPin="openForgotPinModal"
      :loading="loading"
    />

    <ForgotPinModal
      id="pc-forgot-pin-modal"
      :open="showForgotPinModal"
      @onClose="closeForgotPinModal"
      @onActionComplete="onForgotPinModalActionComplete"
      :pinError="forgotPinModalError"
      @onResend="resendOtp"
      :loading="loading"
    />

    <UaRatingsModal
      id="pc-ua-modal"
      :open="showUaModal"
      @onClose="closeUaModal"
      @onActionComplete="onUaModalActionComplete"
      :uaRatings="uaRatings"
      @onSelect="onRatingSelect"
      :loading="loading"
    />

    <OtpModal
      id="pc-otp-modal"
      :open="showOtpModal"
      @onClose="closeOtpModal"
      @onActionComplete="onOtpModalActionComplete"
      @onResend="resendOtp"
      :pinError="otpModalError"
      :loading="loading"
    />
    <div>
      <p class="parental-heading">{{ $t("Enable Parental Control") }}</p>
      <div class="d-flex-parental  mv-20" id="control-group">
        <input
          type="radio"
          id="yes"
          class="radio-input"
          v-model="controlsEnabled"
          value="true"
          @click="() => toggleControls(true)"
        />
        <label class="checkbox-label" for="yes">{{ $t("Yes") }}</label>

        <input
          type="radio"
          id="no"
          class="radio-input"
          value="false"
          v-model="controlsEnabled"
          @click="disableParentalControl"
        />
        <label class="checkbox-label" for="no">{{ $t("No") }}</label>
      </div>
    </div>
    <div v-if="controlsEnabled">
      <p class="heading-pin-enabled" v-if="ispinset">{{$t("You have set the pin")}}</p>
      <div class="mb-10 d-flex-parental">
        <PinLayout
          value="1234"
          disabled="false"
          pinCount="4"
          @onCompleted="onPinComplete"
        />

        <div class="edit-button" @click="openForgotPinModal">
          <div class="edit-icon">
            <profileediticon></profileediticon>
          </div>
            
            <!-- :src="require('@/assets/icons/pencil.png')"
            alt="" -->
  
          <p class="edit-text">{{ $t("Edit") }}</p>
        </div>
      </div>
      <div style="padding-top: 20px">
        <p class="mt-10 secondary-text">
          {{ $t("Edit maturity rating for profiles") }}
        </p>
        <div class="mt-10">
          <!-- <ProfileTile
              iconUrl="account_placeholder.png"
              heading="Mittie Austin"
              description="No viewing description"
            /> -->
          <div
            v-for="userProfile in profilesList"
            :key="userProfile.profileid"
            @click="switchProfileTo(userProfile)"
            class="profiles"
          >
            <div class="profileDetails">
              <ProfileTile
                :iconUrl="userProfile.picture"
                :heading="userProfile.profilename"
                :description="userProfile.description"
                :isKid="userProfile.kidsmode === 'YES' ? true : false"
              />
              <div>
                <!-- <p v-if="subscriberid === userProfile.profileid" class="profileBadge">{{ $t("admin") }}</p> -->
                <!-- <p v-if="userProfile.kidsmode === 'YES'" class="profileBadge">{{ $t("kids") }}</p> -->
                <!-- <p v-if="subscriberid === userProfile.profileid" class="profileBadge">{{ $t("admin") }}</p> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="no-controls-container">
      <img class="no-controls-img" src="@/assets/icons/no_controls.svg" />
      <p class="secondary-heading mt-10">
        {{
          $t("Enable Parental Control to restrict content for your profiles")
        }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import { showSnackBar } from "@/utilities";
export default {
  components: {
    profileediticon: () => import("../../../SvgImages/editIconsvg.vue"),
    PinLayout: () => import("@/components/utils/PinLayout.vue"),
    ProfileTile: () => import("@/components/utils/ProfileTile.vue"),
    PinModal: () => import("@/components/utils/PinModal.vue"),
    ForgotPinModal: () => import("@/components/utils/ForgotPinModal.vue"),
    OtpModal: () => import("@/components/utils/OtpModal.vue"),
    UaRatingsModal: () => import("@/components/utils/UaRatingsModal.vue"),
    Loading: () =>
      import(
        /* webpackChunkName: "Loading" */ "@/components/Templates/Loading.vue"
      ),
  },
  mounted() {
    this.mainLoading = true;
    this.toggleControls(false);
  },
  watch: {
    controlsEnabled(value){
      localStorage.setItem("parentalControlEnabled", value);
      
    }
  },
  methods: {
    ...mapActions([
      "parentalControlStatus",
      "parentalControlPinVerify",
      "parentalControlVerifyOldPin",
      "parentalControlResetPin",
      "parentalControlPinSet",
      "listProfiles",
      "parentalControlPgRating",
      "parentalControlEnableDisable",
    ]),
    onPinComplete(value) {
      //do nothing as the pin in disabled
    },
    enableControls() {
      this.mainLoading = true;
      let payload = {
        parentalcontrol: "ENABLED",
        pin: "",
      };
      this.parentalControlEnableDisable(payload)
        .then((data) => {
          //showing verification popup
          //before open otp model close existing pin modal
          this.controlsEnabled = true;
          this.mainLoading = false;
          this.enableMode = false;
          this.loading = false;
          this.listUserProfiles();
        })
        .catch((error) => {
          console.log("parentalControlResetPin error -> ", error);
        });
    },
    toggleControls(flag) {
      if (flag) {
        if (this.controlsEnabled) {
          return;
        }
        if (!this.ispinset) {
          this.isenabled = null;
          this.editFlag = false;
          this.checkFlag = false;
          this.confirmPin = null;
          this.disableMode = false;
          this.enableMode = false;
          this.setPinFlag = true;
          this.confirm = false;
          this.pgRatingFlag = false;
          this.optBlockFlag = false;
          this.newEditFlag = false;

          this.openPinModal();
          setTimeout(() => {
            this.controlsEnabled = false;
          }, 300);
          return;
        }
        setTimeout(() => {
          this.controlsEnabled = false;
        }, 10);
        this.confirm = true;
        this.pinMode = "confirm";
        this.checkFlag = false;
        this.disableMode = false;
        this.enableMode = true;
        this.openPinModal();

        return;
      }

      this.parentalControlStatus()
        .then((data) => {
          this.mainLoading = false;
          if (data.reason) {
            this.formError = this.$t(data.reason);
            return;
          }
          this.isenabled = data.data.isenabled;
          this.ispinset = data.data.ispinset;
          if (this.isenabled) {
            this.controlsEnabled = true;
            this.listUserProfiles();
          } else if (!this.ispinset) {
            this.controlsEnabled = false;
            // this.openPinModal();
          } else {
            this.controlsEnabled = false;
          }
        })
        .catch((error) => {
          console.log("Captcha error -> ", error);
        });

      //this.controlsEnabled = !this.controlsEnabled;
    },
    disableParentalControl() {
      if (!this.controlsEnabled) {
        return;
      }
      this.pinMode = "confirm";
      this.confirm = true;
      this.disableMode = true;
      this.checkFlag = true;
      this.openPinModal();
      //show confirm pin model and call disable api with pin and DISABLED flag

      //  this.parentalControlEnableDisable()
      //       .then((data) => {
      //         if (data.reason) {
      //           this.formError = this.$t(data.reason);
      //           return;
      //         }
      //         this.isenabled = data.data.isenabled;
      //         this.ispinset = data.data.ispinset;
      //         console.log("parentalControlStatus", this.isenabled, this.ispinset);
      //         if (!this.isenabled && !this.ispinset) {
      //           this.openPinModal();
      //         } else {
      //           this.controlsEnabled = !this.controlsEnabled;
      //           this.listUserProfiles();
      //         }
      //       })
      //       .catch((error) => {
      //         console.log("Captcha error -> ", error);
      //       });
    },
    openPinModal() {
      this.showPinModal = true;
    },
    openEditPinModal() {
      // this.pinMode = "new";
      //this.editFlag = true;
      // this.showPinModal = true;
      //call reset pin api

      let resetPayload = {
        otp_type: "parentalControlPinUpdate",
      };
      this.parentalControlPinSet(resetPayload)
        .then((data) => {
          if (data.data.reason) {
            this.pinModalError = this.$t(data.data.reason);
          }
          
        })
        .catch((error) => {
          console.log("parentalControlResetPin error -> ", error);
        });
      //showing verification popup
      //before open otp model close existing pin modal
      this.showPinModal = false;
      this.editFlag = false;
      this.optBlockFlag = false;
      this.newEditFlag = true;
      this.pinMode = "edit";
      this.openOtpModal();
    },
    closePinModal() {
      this.showPinModal = false;
      if (this.checkFlag) {
        this.checkFlag = false;
        this.controlsEnabled = true;
      }
      this.disableMode = false;
      this.enableMode = false;
      this.newEditFlag = false;
    },
    onPinModalActionComplete(enteredPin) {
      this.pinModalError = "";
      this.loading = true;
      if (this.newEditFlag) {
        let resetPayload = {
          pin: enteredPin,
          OTP: this.editOtp,
        };
        this.parentalControlResetPin(resetPayload)
          .then((data) => {
            
            this.loading = false;
            if (data.data.reason) {
              this.otpModalError = this.$t(data.data.reason);
              this.showOtpModal = true;
              this.showPinModal = false;
              return;
            } else if (data.data.success) {
              this.showOtpModal = false;
              this.showPinModal = false;
              this.newEditFlag = false;
            }
            
          })
          .catch((error) => {
            console.log("parentalControlResetPin error -> ", error);
          });
      } else if (!this.editFlag && !this.confirm) {
        this.setPin = enteredPin;
        let payload = {
          otp_type: "parentalControlPinSet",
        };
        //do something here. (Make call to the API)
        //received otp to email
        this.parentalControlPinSet(payload)
          .then((data) => {
            this.loading = false;

            if (data.data.reason) {
              this.pinModalError = this.$t(data.data.reason);
              return;
            } else if (data.data.success) {
              
              // this.ispinset = true;
              this.showPinModal = false;
              this.optBlockFlag = true;
              this.openOtpModal();
              //this.pinMode= "default";
            }
          })
          .catch((error) => {
            console.log("parentalControlPinSet error -> ", error);
          });
      } else if (this.editFlag) {
        //close setpin modal and open it again
        if (this.pinMode == "new") {
          this.loading = false;
          this.newPin = enteredPin;

          this.closePinModal();
          // this.showPinModal = false;
          this.pinMode = "edit";
        } else if (this.pinMode == "edit") {
          this.oldPin = enteredPin;
          // this.setPin = false;
          // verifiying old pin call verify api
          let payload = {
            pin: this.oldPin,
          };
          this.parentalControlVerifyOldPin(payload)
            .then((data) => {
              if (data.data.reason) {
                this.loading = false;
                this.pinModalError = this.$t(data.data.reason);
                return;
              } else if (data.data.success) {
                
                //call reset pin api
                let resetPayload = {
                  otp_type: "parentalControlPinUpdate",
                };
                this.parentalControlPinSet(resetPayload)
                  .then((data) => {
                    this.loading = false;
                    if (data.data.reason) {
                      this.pinModalError = this.$t(data.data.reason);
                      return;
                    }
                    
                    //showing verification popup
                    //before open otp model close existing pin modal
                    this.showPinModal = false;
                    this.editFlag = false;
                    this.optBlockFlag = false;
                    this.openOtpModal();
                  })
                  .catch((error) => {
                    console.log("parentalControlResetPin error -> ", error);
                  });
              }
            })
            .catch((error) => {
              console.log("parentalControlVerifyOldPin error -> ", error);
            });
        }
        let self = this;
        setTimeout(function() {
          self.openPinModal();
        }, 100);
      } else if (this.confirm) {
        // verifiying old pin call verify api
        this.confirmPin = enteredPin;
        let payload = {
          pin: this.confirmPin,
        };
        this.parentalControlVerifyOldPin(payload)
          .then((data) => {
            if (data.data.reason) {
              this.loading = false;
              this.pinModalError = this.$t(data.data.reason);
              return;
            } else if (data.data.success) {
              this.showPinModal = false;
              this.confirm = false;
              
              this.mainLoading = true;
              //call DISABLED  api
              if (this.disableMode) {
                let disablePayload = {
                  parentalcontrol: "DISABLED",
                  pin: this.confirmPin,
                };
                this.parentalControlEnableDisable(disablePayload)
                  .then((data) => {
                    this.disableMode = false;
                    this.loading = false;
                    this.mainLoading = false;
                    if (data.data.reason) {
                      this.pinModalError = this.$t(data.data.reason);
                      return;
                    }
                    
                    //showing verification popup
                    //before open otp model close existing pin modal
                    this.controlsEnabled = false;
                  })
                  .catch((error) => {
                    console.log("parentalControlResetPin error -> ", error);
                  });
              } else if (this.enableMode) {
                this.enableControls();
              } else if (this.pgRatingFlag) {
                // here api for pg restriction
                let pgRatingPayload = {
                  pgratting: this.pgRating,
                  profileid: this.profileid,
                };
                this.parentalControlPgRating(pgRatingPayload)
                  .then((data) => {
                    this.loading = false;

                    if (data.reason) {
                      this.formError = this.$t(data.data.reason);
                      return;
                    } else if (data.data.success) {
                      this.showUaModal = false;
                      this.listUserProfiles();
                    }
                    
                  })
                  .catch((error) => {
                    console.log("parentalControlPinSet error -> ", error);
                  });
              }
            }
          })
          .catch((error) => {
            console.log("parentalControlVerifyOldPin error -> ", error);
          });
      }
    },

    openForgotPinModal() {
      this.loading = true;
      //call api to get otp
      let payload = {
        otp_type: "parentalControlPinUpdate",
      };
      //do something here. (Make call to the API)
      //received otp to email
      this.parentalControlPinSet(payload)
        .then((data) => {
          this.loading = false;
          if (data.data.reason) {
            this.pinModalError = this.$t(data.data.reason);
            showSnackBar(this.$t(data.data.reason));
            return;
          } else if (data.data.success) {
            this.showForgotPinModal = true;
          }
        })
        .catch((error) => {
          console.log("parentalControlPinSet error -> ", error);
        });
    },
    closeForgotPinModal() {
      this.showForgotPinModal = false;
    },

    openOtpModal() {
      this.showOtpModal = true;
    },
    closeOtpModal() {
      this.newEditFlag = false;
      this.showOtpModal = false;
    },
    onOtpModalActionComplete(enteredPin) {
      this.pinModalError = "";
      //do something here. (Make call to the API)
      if (!this.editFlag && !this.confirm && this.optBlockFlag) {
        this.loading = true;
        let payload = {
          pin: this.setPin,
          OTP: enteredPin,
        };
        this.parentalControlPinVerify(payload)
          .then((data) => {
            this.loading = false;
            if (data.data.reason) {
              this.otpModalError = this.$t(data.data.reason);
              return;
            }
            this.ispinset = true;
            
            // this.editFlag = false;
            // this.confirm =false;
            this.controlsEnabled = !this.controlsEnabled;
            this.listUserProfiles();
            this.optBlockFlag = false;
            this.showOtpModal = false;
          })
          .catch((error) => {
            console.log("parentalControlPinVerify error -> ", error);
          });
      } else {
        this.editOtp = enteredPin;
        this.showOtpModal = false;
        this.showPinModal = true;
      }
    },
    openUaModal() {
      this.showUaModal = true;
    },
    closeUaModal() {
      this.showUaModal = false;
    },
    onUaModalActionComplete(selectedUaList) {
      //do something here. (Make call to the API)
      
      let selectedPgRating = selectedUaList[selectedUaList.length - 1].key;
      this.showUaModal = false;
      if (selectedPgRating === this.pgRating) {
        showSnackBar(this.$t("Nothing to update"));
        return;
      }

      this.pgRating = selectedPgRating;
      this.description = selectedUaList[selectedUaList.length - 1].description;
      // let pgRatingPayload = {
      //   pgratting: pgRating,
      //   profileid: this.profileid,
      // };
      // confirm pin and set pg rating
      this.pinMode = "confirm";
      this.pgRatingFlag = true;
      this.confirm = true;
      this.openPinModal();
      //
      // this.parentalControlPgRating(pgRatingPayload)
      //   .then((data) => {
      //     if (data.reason) {
      //       this.formError = this.$t(data.data.reason);
      //       return;
      //     } else if (data.data.success) {
      //       this.showUaModal = false;
      //     }
      //     console.log("parentalControlPgRating-response", data);
      //   })
      //   .catch((error) => {
      //     console.log("parentalControlPinSet error -> ", error);
      //   });
    },
    resendOtp() {
      //do something here. (Make call to the API)
      this.loading = true;
      let payload = {
        otp_type: "parentalControlPinUpdate",
      };
      //do something here. (Make call to the API)
      //received otp to email
      this.parentalControlPinSet(payload)
        .then((data) => {
          this.loading = false;
          showSnackBar(this.$t("OTP sent on registered email/mobile"));
          if (data.data.reason) {
            this.pinModalError = this.$t(data.data.reason);
            showSnackBar(this.$t(data.data.reason));
            this.showForgotPinModal = false;
            this.showOtpModal=false;
            return;
          } else if (data.data.success) {
            showSnackBar(this.$t("OTP sent on registered email/mobile"));
            
          }
        })
        .catch((error) => {
          console.log("parentalControlPinSet error -> ", error);
        });
    },
    onRatingSelect(index) {
      let data = [...this.uaRatings];
      let slugArr = [];
      for (let i = 0; i < data.length; i++) {
        let slug = data[i];
        if (i <= index) {
          slug.checked = true;
        } else {
          slug.checked = false;
        }
        slugArr.push(slug);
      }
      this.uaRatings = [...slugArr];
    },
    filterUaRatingsSelected(key) {
      let data = [...this.uaRatings];
      let index = 0;
      for (var j = 0; j < data.length; j++) {
        if (data[j].key === key) {
          index = j;
          break;
        }
      }
      this.onRatingSelect(index);
      this.pgRating = this.uaRatings[index].key;
    },
    listUserProfiles() {
      this.mainLoading = true;
      this.listProfiles()
        .then((data) => {
          let profilesList = data.data.data;
          let updatedList = [];
          for (let i = 0; i < profilesList.length; i++) {
            let profile = profilesList[i];
            let rating = profile.pgratting;
            let flag = 0
            for (let j = 0; j < this.uaRatings.length; j++) {
              let uaRating = this.uaRatings[j];
              if (uaRating.key === rating) {
                profile.description = uaRating.description;
                updatedList.push(profile);
                flag++;
                break;
              }
            }
            if (!flag) {
               profile.description = ""
              updatedList.push(profile);
            }

          }
          this.profilesList = updatedList;
          this.mainLoading = false;
          localStorage.setItem(
            "profile-list",
            JSON.stringify(this.profilesList)
          );
          

          //this.profilesList.forEach(profile => {
          //   if(profile.profileid === this.profileid) {
          //     this.subscriberProfileDetails = profile;

          //     console.log("Subscribers profile details" , this.subscriberProfileDetails);

          //     this.subscriberAgeValue = parseInt(this.subscriberProfileDetails.age);

          //     localStorage.setItem("subscriberProfileDetails", JSON.stringify(profile));
          //   }
          // })
        })
        .catch((err) => {
          console.log("Error While fetching profiles -> ", err);
        });
    },
    switchProfileTo(user) {
      this.filterUaRatingsSelected(user.pgratting);
      this.openUaModal();
      this.profileid = user.profileid;
    },

    closeForgotPinModal() {
      this.showForgotPinModal = false;
    },
    onForgotPinModalActionComplete(data) {
      // forget pin reset api call
      this.forgotPinModalError = ""

      this.loading = true;
      let forgetPinPayload = {
        pin: data.pin,
        OTP: data.otp,
      };
      this.parentalControlResetPin(forgetPinPayload)
        .then((data) => {
          this.loading = false;
          if (data.data.reason) {
            this.forgotPinModalError = this.$t(data.data.reason);
            return;
          } else if (data.data.success) {
            this.showForgotPinModal = false;
          }
          
        })
        .catch((error) => {
          console.log("parentalControlResetPin error -> ", error);
        });
    },
  },
  data() {
    return {
      mainLoading: false,
      loading: false,
      controlsEnabled: false,
      showPinModal: false,
      showOtpModal: false,
      showUaModal: false,
      pinModalError: "",
      forgotPinModalError: "",
      otpModalError: "",
      pinMode: "new",
      isenabled: null,
      ispinset: null,
      setPin: null,
      editFlag: false,
      checkFlag: false,
      newPin: "",
      oldPin: null,
      confirmPin: null,
      profilesList: null,
      profileid: null,
      description: null,
      showForgotPinModal: false,
      pgRating: null,
      disableMode: false,
      enableMode: false,
      setPinFlag: true,
      confirm: false,
      pgRatingFlag: false,
      optBlockFlag: false,
      newEditFlag: false,
      //change this later. to be fetched from api
      uaRatings: [
        {
          id: "1",
          key: "U",
          description: this.$t("Show content rated U"),
          checked: false,
        },
        {
          id: "2",
          key: "U/A 7+",
          description: this.$t("Show content rated U/A 7+ and below"),
          checked: false,
        },
        // {
        //   id: "3",
        //   key: "U/A 12+",
        //   description: this.$t("Show content rated U/A 12+ and below"),
        //   checked: false,
        // },
        {
          id: "3",
          key: "U/A 13+",
          description: this.$t("Show content rated U/A 13+ and below"),
          checked: false,
        },
        
        {
          id: "4",
          key: "U/A 16+",
          description: this.$t("Show content rated U/A 16+ and below"),
          checked: false,
        },
        {
          id: "5",
          key: "A",
          description: this.$t("No viewing restrictions"),
          checked: false,
        },
      ],
      //end of change this later
    };
  },
};
</script>

<style lang="scss">
@import "@/sass/_variables.scss";

.parental-container {
  padding: 10px 20px !important;
  min-height: 400px;
}
.checkbox-label {
  font-size: 12px;
  color: $clr-parental-radio-button-border;
  text-transform: uppercase;
  margin-left: 4px;
}

.no-controls-container {
  width: 100%;
  height: 300px;
  text-align: center;
  margin-top: 100px;
}

.no-controls-icon {
  width: 70px;
  height: 70px;
}

.parental-heading {
  color: $clr-par-contr-heading-txt;
  font-size: 20px;
}

.secondary-text {
  color:$clr-par-contrl-edit-txt;
}

.edit-button {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}
.edit-icon {
  width: 12px;
  height: 12px;
}
.edit-text {
  font-size: 12px;
  text-transform: uppercase;
  color:$clr-par-contrl-edit-txt;
}
.edit-text:hover {
  color:$clr-parent-edit-hover;
}

.mt-10 {
  margin-top: 10px;
}
.mv-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.d-flex-parental {
  display: flex;
  gap: 30px;
}

.center {
  justify-content: center;
  align-items: center;
}

.radio-input:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: 0px;
  position: relative;
  background-color: $clr-radio-input-bg;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid $clr-parental-radio-button-border;
  margin-top: 0em;
}

.radio-input:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: 0px;
  position: relative;
  background-color: $clr-saina-main-btn-bg;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid $clr-parental-radio-button-border;
  margin-top: 0em;
  

}

.secondary-heading {
  color: #5c6168;
  font-size: 12px;
}

.parental-main-loader {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2000;
  background-color: rgba($color: #949494, $alpha: 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}
.heading-pin-enabled {
  color: #70787f;
  font-size: 14px;
}
// @media (max-width: 576px) {
//   .radio-input:after,
//   .radio-input:checked:after {
//     width: 0.8rem;
//     height: 0.8rem;
//     border-radius: 10px;
//     top: 5px;
//     border-width: 1px;
//   }
// }
</style>
